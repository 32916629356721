<template>
    <div>
        <div class="flex h-12 bg-teal-500 items-center justify-between">
            <div class="px-5 text-teal-50 text-base">Stockopname</div>
            <a href="#" class="px-5 h-full flex items-center text-teal-50"><i class="icon-clipboard3"></i></a>
        </div>
        <div class="p-5">
            <button @click="isScanner = true" class="h-10 w-full">Scann</button>
            <div v-if="isScanner" class="mb-5">
                <StreamBarcodeReader
                    @decode="onDecode"
                    @loaded="onLoaded"
                >
                </StreamBarcodeReader>
            </div>
            <div class="mb-5">
                <div class="flex items-center relative">
                    <span class="absolute bg-gray-100 border-b border-teal-500 w-10 justify-center h-full flex items-center"><i class="icon-barcode2 icon-1x"></i></span>
                    <input v-model="barcode" type="text" class="h-10 rounded-none w-full border-b pl-12 focus:outline-none text-base border-teal-500 bg-gray-50" placeholder="On Hand">
                </div>
            </div>
            <div class="mb-5">
                <div class="flex items-center relative">
                    <span class="absolute bg-gray-100 border-b border-teal-500 w-10 justify-center h-full flex items-center"><i class="icon-price-tag2 icon-1x"></i></span>
                    <input value="Sandal Pria Tarumpah Jepit Warna Cokelat Size 45" type="text" class="h-10 rounded-none w-full border-b pl-12 focus:outline-none text-base border-teal-500 bg-gray-50" placeholder="On Hand">
                </div>
            </div>
            <div class="mb-5">
                <div class="flex items-center relative">
                    <span class="absolute bg-gray-100 border-b border-teal-500 w-10 justify-center h-full flex items-center"><i class="icon-grab icon-1x pb-1"></i></span>
                    <input type="text" class="h-10 rounded-none w-full border-b pl-12 focus:outline-none text-base border-teal-500 bg-gray-50" placeholder="On Hand">
                </div>
            </div>
            <div class="mb-5">
                <div class="flex items-center relative">
                    <span class="absolute bg-gray-100 border-b border-teal-500 w-10 justify-center h-full flex items-center"><i class="icon-stack-check icon-1x"></i></span>
                    <input type="text" class="h-10 rounded-none w-full border-b pl-12 focus:outline-none text-base border-teal-500 bg-gray-50" placeholder="On Hand">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
    components: {StreamBarcodeReader},
    data () {
        return {
            isScanner: false,
            barcode: ''
        }
    },
    methods: {
        onLoaded () {
            console.log()
        },
        onDecode (result) {
            this.barcode = result
        }
    }
}
</script>